import { navigate } from "gatsby"

export const segmentize = uri =>
  uri
    // strip starting/ending slashes
    .replace(/(^\/+|\/+$)/g, "")
    .split("/");

// Set page but don't reload
export function setPageURL(url, state) {
  if (typeof window !== `undefined` && typeof url !== `undefined`) {
    window.history.pushState(state, '', url);
  }
  return
}


export const handleEventRoute = (
  eventCard,
  location = {},
  theme = null,
  shouldReplace = false,
  shouldReload = false
) => {
  const { slug, id } = eventCard
  //console.log('DEBUG: handleEventRoute ', eventCard, theme);

  let path = `/events/details/${id}`

  if (theme == `peoria`) {
    const domain = 'https://peoria.org'
    path = `${domain}/event/${slug}`

  }

  return path
}


export const handlePlaceRoute = (
  place,
  location = {},
  theme = null,
  shouldReplace = false,
  shouldReload = false
) => {
  const { slug, id } = place.properties
  const paths = segmentize(location.pathname)
  const currentPage = `/${paths[0]}`
  const trailingSlash = currentPage.endsWith("/") ? "" : "/"
  const page = currentPage + trailingSlash

  const placeID = slug ? slug : id

  // TODO: Make reusable
  const hasTheme = theme == `peoria`
  const hasThemeLink = theme == `peoria` && place?.properties?.data_source_url?.includes('peoria')
  const newURL = (hasThemeLink)
    ? place?.properties?.data_source_url
    : page + placeID + location.search

  if (hasTheme) {
    if (window) {
      window.parent.location.href = newURL
    }
  } else {
    console.log('Navigating to ', newURL, ' with replace ', shouldReplace, ' and state ', place, '')
    // replace controls whether the current history entry is replaced or a new one is added
    const urlState = {
      place: place
    }

    // Handle reload and broswer history
    if (shouldReload) {
      navigate(newURL, {
        replace: shouldReplace,
        state: {
          place: place
        }
      })
    } else {
      setPageURL(newURL, urlState)
    }
  }
}