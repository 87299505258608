import React, { lazy, Suspense } from "react"
import PropTypes from "prop-types"

import Headroom from "react-headroom"
import { useLocation } from '@reach/router';
import { pageStore } from "state/store-zustand";

import MainMenu from "components/header/menus/mainMenu"
import TopMenu from "components/header/menus/topMenu"
const MobileMenu = lazy(() => import('./menus/mobileMenu'))

import "./header.scss"

const Header = ({ city, citySelected, showSearch }) => {

  const isPhone = pageStore(state => state.isPhone)
  const isTablet = pageStore(state => state.isTablet)

  const location = useLocation()
  const businessPath = `businesses`;
  const partnerPath = `partners`;

  const isAltNaviation = location.pathname.includes(businessPath) || location.pathname.includes(partnerPath)

  return (
    <Headroom>
      <header className="main" id="main-header">
        {!isPhone &&
          <Suspense fallback={null}>
            <TopMenu />
          </Suspense>
        }
        {/* {isAltNaviation &&
          <h1>location{window.location.pathname}</h1>
        } */}
        <MainMenu
          city={city}
          citySelected={citySelected}
          showSearch={showSearch} />

        {(isPhone || isTablet) &&
        <Suspense fallback={null}>
          <MobileMenu />
        </Suspense>
        }
      </header>
    </Headroom>
  )
}

Header.propTypes = {
  showSearch: PropTypes.bool,
}

Header.defaultProps = {
  citySelected: false,
  showSearch: true,
}

export default Header
